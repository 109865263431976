/* Overlay that covers the entire page with a semi-transparent background */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the spinner is above other content */
  }
  
  /* Container for spinner and text */
  .spinner-container {
    text-align: center;
  }
  
  /* Loading text styling */
  .loading-text {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-top: 16px; /* Space between spinner and text */
  }
  