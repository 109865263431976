/* Container */
.table-container {
  display: flex; /* Ensures flexbox behavior */
  flex-direction: column; /* Align items vertically */
  align-items: center; /* Center content horizontally */
  margin: auto;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5);
}

/* Title */
.table-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Table Wrapper */
.table-wrapper {
  overflow-x: auto;
  width: 100%;
}

/* Table */
.responsive-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.responsive-table thead {
  background-color: #172639;
  color: #fff;
  cursor: pointer;
}

.responsive-table th,
.responsive-table td {
  padding: 12px 15px; /* Add padding for better readability */
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.responsive-table tbody tr:hover {
  background-color: rgb(225, 225, 225);
  cursor: pointer;
}

/* Pagination styles */
.pagination {
  margin-top: 20px; /* Add space above the pagination */
  display: flex;
  justify-content: center; /* Center the pagination */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .table-container {
    width: 100%;
  }
}
