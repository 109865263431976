.custBtn {
    background-color: #1abc9c!important;
    color: white !important;
    padding: 10px 20px !important;
    border: none !important;
    border-radius: 5px !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    transition: background-color 0.3s, box-shadow 0.3s !important;
  }
  
  .custBtn:hover {
    background-color: #16a085 !important;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2) !important;
    transform: translateY(-1px);
  }
  
  .customShadow{
    /* padding: 20px; */padding-bottom: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5);
  }