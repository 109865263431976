.OurDifferContainer {
    background-color: #172639;
    color: white;
    height: auto;
    padding-bottom: 45px;
  }
  
  .OurDifferContainer .sectionHeading {
    font-weight: 900;
    color: white;
    /* text-shadow: 0.50px 0.50px 1px black; */
    letter-spacing: 1px;
  }
  
  .OurDifferContainer .sectionHeading:hover {
    font-weight: 900;
    color: #eaa627;
    letter-spacing: 1.5px;
  }
  
  .OurDifferContainer h4:hover {
    letter-spacing: 2px;
    transition: 0.45s ease-in-out;
    color: #eaa627;
  }
  
  .OurDifferContainer .Differ_Services {
    color: white;
  }
  
  .OurDifferContainer .differ_Card {
    width: 100%;
    max-width: 30rem;
    height: 45vh;
    color: white;
    background: rgba(255, 255, 255, 0);
    border: 2px solid #eaa627 !important;
    border-radius: 10px;
    text-align: justify;
  }
  
  .OurDifferContainer .differ_Card p {
    text-align: justify;
  }
  
  .OurDifferContainer .differ_Card:hover {
    border: 2px solid #ffffff !important;
    transform: scale(1.01);
    background: rgba(0, 0, 0, 0.03);
    background-color: rgb(28, 28, 28);
    border: 2px solid #ffffff !important;
    transition: 1s ease-in-out;
    cursor: pointer;
  }
  
  .OurDifferContainer .differ_Card h5 {
    font-weight: 700;
    color: #eaa627;
  }
  
  .OurDifferContainer .differ_Card p:hover {
    transition: 1s ease-in-out;
    color: #eaa627;
  }
  
  .OurDifferContainer .differ_Card h5:hover {
    letter-spacing: 1px;
    transition: 0.45s ease-in-out;
  }
  
  /* Media Queries for different screen sizes */
  
  @media (max-width: 1200px) {
    .OurDifferContainer .sectionHeading {
      font-size: 1.8rem;
    }
    .OurDifferContainer .differ_Card {
      max-width: 100%;
      height: auto;
    }
  }
  
  @media (max-width: 992px) {
    .OurDifferContainer .sectionHeading {
      font-size: 1.6rem;
    }
    .OurDifferContainer .differ_Card {
      max-width: 100%;
      height: auto;
    }
  }
  
  @media (max-width: 768px) {
    .OurDifferContainer .sectionHeading {
      font-size: 1.4rem;
    }
    .OurDifferContainer .differ_Card {
      max-width: 100%;
      height: auto;
    }
  }
  
  @media (max-width: 576px) {
    .OurDifferContainer .sectionHeading {
      font-size: 1.2rem;
    }
    .OurDifferContainer .differ_Card {
      max-width: 100%;
      height: auto;
    }
  }
  