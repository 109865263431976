.mainBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap; /* Ensure items wrap on smaller screens */
  }
  
  .aboutUsContainer {
    background-color: #172639;
    color: white;
    padding: 20px; /* Added padding for better spacing */
  }
  
  .aboutUsContainer h4:hover {
    letter-spacing: 2px;
    transition: 0.45s ease-in-out;
    color: #eaa627;
    cursor: pointer;
  }
  
  .aboutUsContainer p:hover {
    transition: 1s ease-in-out;
    color: #eaa627;
    cursor: pointer;
  }
  
  .aboutUsContainer:hover {
    color: white;
    background-color: #111111;
    transition: 0.5s ease-in-out;
  }
  
  .aboutUsContainer .sectionHeading {
    font-weight: 900;
    color: white;
    letter-spacing: 1px;
  }
  
  .aboutUsContainer .aboutUsText p {
    margin: auto 15px;
    font-weight: 400;
    text-align: justify;
  }
  
  .aboutUsVideo .video-container {
    display: block;
    margin: auto;
    text-align: center;
    height: 40vh;
    width: 90%;
    border-radius: 20px;
    border: 2px solid white;
    cursor: pointer;
  }
  
  .aboutUsVideo .video-container:hover {
    border: 2px solid #eaa627;
    -webkit-transform: scale(1.1);
    transform: scale(1.03);
    transition: 1s ease-in-out;
  }
  
  /* Responsive Styles */
  @media (max-width: 1200px) {
    .aboutUsVideo .video-container {
      width: 100%;
      height: 20vh;
    }
  
    .aboutUsContainer .aboutUsText p {
      font-size: 0.95rem; /* Slightly smaller text */
    }
  }
  
  @media (max-width: 992px) {
    .aboutUsVideo .video-container {
      width: 100%; /* Increase video container width */
      height: 20vh; /* Adjust height */
    }
  
    .aboutUsContainer .aboutUsText p {
      font-size: 0.9rem; /* Adjust text size */
      margin: 0 10px; /* Reduce margin */
    }
  
    .aboutUsContainer {
      padding: 15px; /* Adjust padding */
    }
  
    .aboutUsContainer .sectionHeading {
      font-size: 1.8rem; /* Reduce heading size */
    }
  }
  
  @media (max-width: 768px) {
    .aboutUsVideo .video-container {
      width: 100%; /* Full width */
      height: 25vh; /* Adjust height */
    }
  
    .aboutUsContainer .aboutUsText p {
      font-size: 0.85rem; /* Further reduce text size */
    }
  
    .aboutUsContainer .sectionHeading {
      font-size: 1.6rem; /* Reduce heading size */
    }
  }
  
  @media (max-width: 576px) {
    .aboutUsVideo .video-container {
      width: 100%; /* Full width */
      height: 25vh; /* Further reduce height */
    }
  
    .aboutUsContainer .aboutUsText p {
      font-size: 0.8rem; /* Smaller text for mobile */
      margin: 0 5px; /* Reduce margin */
    }
  
    .aboutUsContainer .sectionHeading {
      font-size: 1.4rem; /* Smaller heading size */
    }
  }
  