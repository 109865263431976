
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



.sidebar {
    background-color: #172639;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 250px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
    font-style: normal;
    /* box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f; */
    transition: width 0.5s;
    /* background-image: linear-gradient(to bottom right, #172639,#0003); */
}

.cust-bg {
   
    border: none !important;
    border-radius: 0 !important;
}

.cust-item {  
    background-color: var(--secondary-color);
    color: var(--primary-color); 
    border: none !important;  
    margin: 4px 0px;
}

.cust-item a{
    color: var(--primary-color);
}

.cust-item.active {
    /* background-color:var(--primary-color);
    color: white; */
    background-color: #f0f0f0;
    color: var(--secondary-color);

}


.cust-item.active a{
    /* background-color: var(--primary-color);
    color: white; */
    background-color: #f0f0f0;
    color: var(--secondary-color);
}

.cust-item:hover {
    /* background-color: var(--primary-color);
    color:white; */
    background-color: #f0f0f0;
    color: var(--secondary-color);
}

.cust-item:hover a{
    /* background-color: var(--primary-color);
    color:white; */
    background-color: #f0f0f0;
    color: var(--secondary-color);
}
.sidebar-h2 {
    /* color: var(--primary-color); */
    color: #f0f0f0;
    border-bottom: 1px solid var(--primary-color);
    font-size: 30px;
    height: 60px;
    margin: 0;
    transition-delay: 0.5s;
}



.title-div {
    height: 60px;
    border-bottom: 1px solid var(--primary-color);
   
}

.bank-sidebar .nav-link {
   
    margin-left: 10px;
}




.nav-item .nav-link {
    text-align: left;
    border: none;
    background: none;
}



.top-bar {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    margin-left: 250px;
}
.mainn-content {   
     margin-left: 250px;
     margin-top: 60px; /* Push content below the Topbar */
     height: calc(100vh - 60px); /* Full height minus Topbar */
     overflow-y: auto; /* Enable scrolling for main content */
     padding: 20px;
     transition: margin-left 0.3s ease; /* Smooth transition for sidebar collapse */    
}



.cust-row {
    height: 60px;
}

.top-bar h3 {
    color: var(--secondary-color);
    font-weight: bold;
}

.vr {
    height: 100%;
}

.main-content {
    margin-top: 60px;
    margin-left: 250px;
}


/* to set setting in the bottom of sidebar */
.bottom-div {
    position: absolute;
    bottom: 0;
    margin-bottom: 30px;
}



.roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.roboto-light {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.roboto-black {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.roboto-thin-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.roboto-medium-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.roboto-bold-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.roboto-black-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
}



.toggle-button {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;    
    cursor: pointer;
    background-color: var(--secondary-color);
    border-radius: 5px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2); /* Shadow effect */
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }

  .toggle-button div{
    width: 25px;
    height: 2px;
    /* background-color: var(--primary-color); */
    background-color: #f0f0f0;
    margin: 6px 0;
  }

  .toggle-button:hover {
     box-shadow: 0px 6px 8px rgba(252, 252, 252, 0.2);
    transform: translateY(-2px);
  }
  
  .toggle-button:active {
    box-shadow: 0px 2px 4px rgba(252, 252, 252, 0.2);
    transform: translateY(1px); 
    
  }

.sidebar-option{  
   
   transition-delay: 0.3;
}



  .sideCollapse.sidebar
  {   
    width: 60px;
  }
  .sideCollapse span.sidebar-option{ 
    width: 0px;
    display: none;

}
.sideCollapse.top-bar
{   
  margin-left: 60px;
}

.sideCollapse.mainn-content
{   
  margin-left: 60px;
}
/* ------------------------------------------media query--------------------------------------------- */

@media screen and (max-width:768px)  
{
    
   
}

@media screen and (min-width:768px)  
{
    
}